import * as CONSTANTS from '../actions/constants';

import {env} from "../../env"
let { initialState } = env;

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.FETCHING_LIST_DATA: {
      return {
        ...state,
        isFetching: true,
        errorFetching: false
      }
    }
    case CONSTANTS.LIST_DATA_FETCHED: {
      return {
        ...state,
        result: action.result,
        isFetching: false,
        pageCount: action.result.pageCount,
        hasMore: action.result.hasMore
      }
    }
    case CONSTANTS.FETCHING_LIST_DATA_FAILURE: {
      return {
        ...state,
        isFetching: false,
        errorFetching: true
      }
    }
    case CONSTANTS.ITEMS_PER_PAGE_SELECTED: {
      return {
        ...state,
        itemsPerPage: action.itemsPerPage
      }
    }
    case CONSTANTS.NUMBER_PAGE_SELECTED: {
      return {
        ...state,
        selectedPage: action.selectedPage
      }
    }
    case CONSTANTS.USER_FILTER_DATA: {
      return {
        ...state,
        filterData: action.filterData
      }
    }
    case CONSTANTS.ACTIVITIES_BY_ID: {
      return {
        ...state,
        isFetching: false,
        errorFetching: false,
        activitiesById: action.activityData
      }
    }
    case CONSTANTS.ONLY_VALIDS: {
      return {
        ...state,
        onlyValids: action.onlyValids
      }
    }
    case CONSTANTS.HASHVALUE : {
      return {
        ...state,
        hashValue: action.hashValue,
        hashIsFetched: false
      }
    }
    case CONSTANTS.HASH_RESPONSE: {
      const noData = action.hashResponse && !action.hashResponse.hasOwnProperty("id") ? true : false;
      return {
        ...state,
        isFetching: false,
        errorFetching: false,
        hashResponse: !noData ? action.hashResponse : null,
        hashIsFetched: true
      }
    }
    case CONSTANTS.RESET_HASH_RESPONSE: {
      return {
        ...state,
        isFetching: false,
        errorFetching: false,
        hashResponse : null,
        hashIsFetched: false
      }
    }
    case CONSTANTS.RESET_PROVIDERS_LIST_RESULTS: {
      return {
        ...state,
        isFetching: false,
        errorFetching: false,
        result: null
      }
    }

    case CONSTANTS.SET_ITEMS_TO_SELECT:{
      return {
        ...state,
        isFetching: false,
        errorFetching: false,
        itemsToSelect: action.payload.data
      }
    }

    case CONSTANTS.SET_SUB_ITEMS_TO_SELECT:{
      return {
        ...state,
        isFetching: false,
        errorFetching: false,
        subItemsToSelect: action.payload.data
      }
    }

    case CONSTANTS.SET_PROVIDER_TRANSACT_TYPES:{
      return {
        ...state,
        isFetching: false,
        errorFetching: false,
        providerTransactTypes: action.payload
      }
    }
    case CONSTANTS.GET_STATUS_API_REQUEST: {
      return {
        ...state,
        isFetching: true
      }
    }
    case CONSTANTS.GET_STATUS_API_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        statusAfip: action.payload
      }
    }
    case CONSTANTS.GET_STATUS_API_FRAILURE: {
      return {
        ...state,
        isFetching: false,
        statusAfip: action.payload
      }
    }

    default: return state
  }
}

export default rootReducer;

export const getRootReducer = state => state.rootReducer;

export const listResults = (state) => getRootReducer(state).result;
export const isFetching = state => getRootReducer(state).isFetching;
export const errorFetching = (state) => getRootReducer(state).errorFetching;
export const itemsPerPage = (state) => getRootReducer(state).itemsPerPage;
export const selectedPage = (state) => getRootReducer(state).selectedPage;
export const pageCount = (state) => getRootReducer(state).pageCount;
export const hasMore = (state) => getRootReducer(state).hasMore;
export const filterData = (state) => getRootReducer(state).filterData;
export const activitiesById = (state) => getRootReducer(state).activitiesById;
export const onlyValids = (state) => getRootReducer(state).onlyValids;
export const hashValue = (state) => getRootReducer(state).hashValue;
export const hashResponse = (state) => getRootReducer(state).hashResponse;
export const itemsToSelect = state => getRootReducer(state).itemsToSelect;
export const subItemsToSelect = state => getRootReducer(state).subItemsToSelect;
export const providerTransactTypes = state => getRootReducer(state).providerTransactTypes;
export const hashIsFetched = state => getRootReducer(state).hashIsFetched;
export const getStatus = state => getRootReducer(state).statusAfip;