import axios from 'axios';
import {transFormToJson} from "./utils";

import {env} from "../env"
let { urlBackend } = env;

const getProviderList = (pageNum, pageSize, filterData) => {
  return axios.get(urlBackend, {
    params: {
        getListProvider: "getListProvider",
        pageId: pageNum,
        pageSize: pageSize,
        filter: filterData
    }
  })
  .then(response => response.data)
  .catch(error => { throw new Error("Error retrieving the providerList information", error)})
}

const getActivitiesById = (id) => { 
  return axios.get(urlBackend, { 
    params: { 
        activitiesById: "ActivitiesById",
        id: id
    } 
  })
  .then(response => response.data)
  .catch(error => { throw new Error("Error retrieving the providerList information", error) })
}

const getItems = (page="1", pageSize="100" ) => {
  return  axios.get(urlBackend, { 
    params: {
        getRubros: "getRubros",
        pageId: page,
        pageSize: pageSize,
    }
  })
  .then(response => response.data)
  .catch(error => { throw new Error("Error retrieving the Items information", error) } )
}

const getSubItems = (itemId) => {
  return  axios.get(urlBackend, {
    params: {
        getSubRubros: "getSubRubros",
        itemId: itemId
    }
  })
  .then(response => response.data)
  .catch(error => { throw new Error("Error retrieving the subItems information", error) })
}

const getHashStatus = (hash) => {
  return axios.get(urlBackend, {
    params: {
      hash
    }
  })
}

const getProviderTransactTypes = () => {
  return axios.get(urlBackend, {
    params: {
      transactTypes: "transactTypes"
    }
  })
  .then(response => response.data)
  .catch(error => { throw new Error("Error retrieving the subItems information", error) })
}

const getProviderInstructions = (id) => {
  return axios.get(urlBackend, {
    params:{
      id
    }
  })
  .then(response => response.data)
  .catch(error => { throw new Error("Error retrieving the subItems information", error) })
}



const getAfipStatus = async () => {
  return await axios.get(urlBackend , {
   params: {
    statusAfip: "statusAfip"
   }
  })
  .then(response =>{
    if(response?.data?.error){
      return response
    } else {
      return transFormToJson($.parseXML(response.data))
    }
  })
  .catch(error => {return error})
}

export { getProviderList, getActivitiesById, getItems, getSubItems, getHashStatus, getProviderTransactTypes, getProviderInstructions, getAfipStatus };