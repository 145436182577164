import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';

import { Container, Row, Col, Nav, NavDropdown, Card, CardColumns, Alert } from 'react-bootstrap';

import * as ROUTES from 'Src/routes';
import ErrorAfip from "../components/commons/ErrorAfip";
import InscripcionImg from '../../public/constancia-de-inscripcion.png';
import ProveedoresImg from '../../public/consulta-de-proveedores.png';
import InstructivosImg from '../../public/instructivos-de-tramites.png';
import RequisitosImg from '../../public/requisitos-de-tramites.png';
import {getStatus} from "../redux/reducers/";
import {useDispatch, useSelector} from "react-redux";
import {getStatusAfip} from "../redux/actions/actions";
import ReactGA from "react-ga";
import manualFile from "../../public/Manual_usuario_tramites.pdf"

import {env} from "../env"
let { cartelMantenimientoVisible, cartelMantenimientoText } = env;

function Dashboard(props) {

  const dispatch = useDispatch();
  const customStyle = {
    minHeight: "100Vh"
  };
  const statusAfip =  useSelector(state => getStatus(state));
  const cardBodyStyles = {
    minHeight: "120px"
  };

  const routes = ROUTES;

  const isMobile = window.screen.width <= 800 && window.screen.width <= 600;

  const columns = !isMobile ? "2" : "1";

  ReactGA.pageview(window.location.pathname + window.location.search);

  useEffect(() => { dispatch(getStatusAfip())}, []);

  return (
    <Container className="page-container mb-5">

      {
        // Cartel mantenimiento
        cartelMantenimientoVisible && cartelMantenimientoText ?
        <Card bg="warning" className="mb-1 mt-5">
          <Card.Body>
            <Card.Title> Aviso importante! </Card.Title>
            <Card.Text>
              {cartelMantenimientoText}
            </Card.Text>
          </Card.Body>
        </Card>
      :
        null
      }

        <Row>
          <Col sm={12}>
            {/* {statusAfip?.data?.error ? <Alert variant="warning" className="text-center">No se puede consultar situación de estado de AFIP. </Alert>  
             :    
             statusAfip?.DummyResponse?.appserver == "OK" && statusAfip?.DummyResponse?.dbserver == "OK" && statusAfip?.DummyResponse?.authserver == "OK" 
             ?
             null :
             <ErrorAfip />} */}
            <Card className="border-0 box-shadow mt-5"  style={customStyle}>
            <Card.Header className="bg-white">
              <h5 className="pl-2">Bienvenido al portal del Registro Oficial de Proveedores del Estado</h5>
              <small className="text-muted px-3">Aquí usted podrá consultar el listado de los Proveedores habilitados por código,
                los requisitos para trámites relacionados y el listado de proveedores de la provincia
              </small>
            </Card.Header>

              <Card.Body>
                <CardColumns style={{ columnCount: columns }}>
                      <Card>
                        <Card.Header className="p-0">
                        <img src={InscripcionImg} style={{width: "100%"}} />
                        </Card.Header>
                        <h5 className="text-center pt-2">Consulta de proveedores</h5>
                        <Card.Body style={cardBodyStyles}>
                          <p>Aquí usted podrá consultar el listado de los Proveedores habilitados.</p>
                        </Card.Body>
                        <Card.Footer className="bg-white" style={{minHeight: "56px"}}>
                            <Link className="color-black" to={routes.PROVIDERS_QUERY}>
                              <button className="btn btn-primary btn-sm mr-3">
                                Consultar
                              </button>
                            </Link>
                        </Card.Footer>
                      </Card>

                      <Card>
                        <Card.Header className="p-0">
                          <img src={RequisitosImg} style={{width: "100%"}} />
                          </Card.Header>
                          <h5 className="text-center pt-2">Requisitos de trámites</h5>
                        <Card.Body style={cardBodyStyles}>
                          <p>Aquí usted podrá consultar los requisitos para realizar los siguientes trámites de Proveedores.</p>
                        </Card.Body>
                        <Card.Footer className="bg-white px-0" style={{minHeight: "56px"}}>
                          <NavDropdown title="Requisitos de Trámites" id="nav-dropdown" className="pl-3">
                              <NavDropdown.Item eventKey="1.1">
                                <Nav.Link className="nav-level-1 py-0">
                                  <Link className="color-black" to={routes.REQUISITO_INSCRIPCION_TRAMITES_URI}>Trámite de Inscripción</Link>
                                </Nav.Link>
                              </NavDropdown.Item>

                              <NavDropdown.Item eventKey="1.2">
                                <Nav.Link className="nav-level-1 py-0">
                                  <Link className="color-black" to={routes.REQUISITO_MODIFICACION_TRAMITES_URI}>Trámite de Modificación de datos</Link>
                                </Nav.Link>
                              </NavDropdown.Item>

                              <NavDropdown.Item eventKey="1.3">
                                <Nav.Link className="nav-level-1 py-0">
                                  <Link className="color-black" to={routes.REQUISITO_ACTUALIZACION_TRAMITES_URI}>Trámite de Actualización</Link>
                                </Nav.Link>
                              </NavDropdown.Item>

                              <NavDropdown.Item eventKey="1.4">
                                <Nav.Link className="nav-level-1 py-0">
                                  <Link className="color-black" to={routes.REQUISITO_REINSCRIPCION_TRAMITES_URI}>Trámite de Reinscripción</Link>
                                </Nav.Link>
                              </NavDropdown.Item>
                          </NavDropdown>
                        </Card.Footer>
                      </Card>

                      <Card>
                        <Card.Header className="p-0">
                         <img src={ProveedoresImg} style={{width: "100%"}} />
                          </Card.Header>
                          <h5 className="text-center pt-2">Verificación de Constancia</h5>
                        <Card.Body style={cardBodyStyles}>
                          <p>Aquí usted podrá consultar la validez de las constancias emitidas.</p>

                        </Card.Body>
                        <Card.Footer className="bg-white" style={{minHeight: "56px"}}>
                            <Link className="color-black" to={routes.REGISTRATION_PROOF}>
                              <button className="btn btn-primary btn-sm mr-3">
                                Consultar
                              </button>
                            </Link>
                        </Card.Footer>
                      </Card>

                      <Card>
                        <Card.Header className="p-0">
                          <img src={InstructivosImg} style={{width: "100%"}} />
                        </Card.Header>
                        <h5 className="text-center pt-2">Sistema de trámites</h5>
                        <Card.Body style={cardBodyStyles}>
                          <p>Aquí usted podrá descargar el manual para el uso del sistema de trámites.</p>
                        </Card.Body>
                        <Card.Footer className="bg-white" style={{minHeight: "56px"}}>
                            <a href={manualFile} target="_blank">
                              <button className="btn btn-primary btn-sm mr-3">
                                Descargar Manual
                              </button>
                            </a>
                            <a className="color-black"  href={ROUTES.PROVIDER_SYSTEM}>
                                <button className="btn btn-primary btn-sm mr-3 float-right">
                                  Ingresar al Sistema de Trámites
                                </button>
                            </a>
                        </Card.Footer>
                      </Card>
                  </CardColumns>
              </Card.Body>
            </Card>
          </Col>
        </Row>
    </Container>
  )
}

export default Dashboard
