import React from 'react';

const Disclaimer = (props) => {

  const { tramiteName } = props;
  return (
    <div className="mb-3">
      <small>
        <b>
          Sr. Ciudadano, para realizar el trámite de {tramiteName} ante el Registro de Proveedores de la Provincia, 
                  Ud. debe ingresar en MISTrámites – <a href="https://sistema.proveedores.misionescontaduria.com/" target="_blank">https://sistema.proveedores.misionescontaduria.com/</a> – completar los datos requeridos, adjuntar 
          en formato pdf/png/jpg los requisitos que se detallan a continuación. 

          Luego de ello, y dentro de los 30 días corridos de realizado lo mencionado, debe enviar a Av. Polonia 1223 Posadas – Misiones CP (3300)
          la documentación autenticada y legalizada – si correspondiere- para la finalización del trámite. 
      </b>
      </small>
    </div>
  )
}

export default Disclaimer;
