import React from 'react';
import ProviderOrderedList from '../components/ProviderOrderedList';
import ErrorEmptyProviderOrderedList from '../components/ErrorEmptyProviderOrderedList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faSadTear } from '@fortawesome/free-solid-svg-icons';

import Pagination from 'react-js-pagination';

import ListHeader from '../components/ListHeader';

import { hashValue, hashResponse } from '../redux/reducers/index';
import { connect } from 'react-redux';

import {env} from "../env"
let { paginationNumberConfig } = env;
let { providerStatusCodes } = env;

const ProviderListCard = (props) => {

    let itemsData = props.globalProps.listResults;

    const { status } = providerStatusCodes;

    let listItemComponent = (elem) => <ProviderOrderedList itemData={elem} globalProps={props.globalProps} icon={faFileAlt}></ProviderOrderedList>;

    /**
     * 
     * @param {Array} dataArr 
     * @description filter the validate date of provider.
     */
    let filterValids = dataArr => dataArr.filter( elem => elem.status === status.soloVigentes.code ? elem : null);

    /**
     * 
     * @param {Array} dataArr 
     * @description Map all provider items data.
     */
    let allItems = dataArr => dataArr.map( element => listItemComponent(element) );

    /**
     * 
     * @param {Array} dataArr 
     * @description Filter the "en Trámite" status for Provider also like "en Revisión" in Safi.
     */
    let filterEnTramite = dataArr => dataArr.filter(elem => elem === status.enRevisionSafi.code ? elem : null)

    /**
     * Mapping the Result of listProvider API and render the list Items HTML.
     */
    let renderItem = () => {
      if(itemsData && itemsData.data && itemsData.data.length > 0){
          if(props.globalProps.onlyValids){
            return filterValids(itemsData.data).map( item => listItemComponent(item) );

          }else if(filterEnTramite(itemsData.data).length > 0){
            return filterEnTramite(itemsData.data).map(item => listItemComponent(item) );

          }else{
            return allItems(itemsData.data)
          }
        
      }else{
        return <ErrorEmptyProviderOrderedList icon={faSadTear} />
      }
    }

    const handlePageChange = (e) => props.globalProps.setPageSelected(e);

    let pageNumbersToShow = paginationData => {
      return (
        <Pagination activePage={parseInt(paginationData.selectedPage)} totalItemsCount={paginationData.listResults.totalCount}
          itemClass={"page-item"} linkClass={"page-link"} itemsCountPerPage={parseInt(paginationData.itemsPerPage)} 
          pageRangeDisplayed={paginationNumberConfig.numberToShow} onChange={handlePageChange} 
        />
      )
    }

    return(
      <main>
            <ul class="gradient-list">
              { 
                !itemsData 
                ?
                  null
                :
                  itemsData?.data?.length > 0
                ? 
                  <React.Fragment>
                    <ListHeader />
                    { renderItem()}
                  </React.Fragment>
                : 
                  <ErrorEmptyProviderOrderedList icon={faSadTear} isFromHashResponse={false} />
              }
            </ul>
        <nav className="pagination-custom-styles d-flex justify-content-center">
            { itemsData?.data?.length > 0 ? pageNumbersToShow(props.globalProps) : null }
        </nav>
      </main>
    )
}

const mapStateToProps = state => {
  return {
   hashValue: hashValue(state),
   hashResponse: hashResponse(state)
  }
}

export default connect(mapStateToProps)(ProviderListCard);