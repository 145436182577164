import React, { useState } from 'react';

import { Container, Accordion, Col, Card } from 'react-bootstrap';

import Disclaimer from './commons/disclaimer';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown } from '@fortawesome/free-solid-svg-icons';
import { faSortUp } from '@fortawesome/free-solid-svg-icons';

import ReactGA from "react-ga";

const ModificationRequirements = (props) => {

  const [changeFirstIcon, setChangeFirstIcon ] = useState(faSortDown);
  const [changeSecondIcon, setChangeSecondIcon ] = useState(faSortUp);

  const handleFirstChangeIcon = () => changeFirstIcon === faSortDown ? setChangeFirstIcon(faSortUp) : setChangeFirstIcon(faSortDown);

  const handleSecondChangeIcon = () => changeSecondIcon === faSortDown ? setChangeSecondIcon(faSortUp) : setChangeSecondIcon(faSortDown);

  ReactGA.event({
      category: 'Requisitos de trámites',
      action: 'Ver trámite de modificación'
  });

  return (
    <Col md={10} xs={12} lg={12} xl={12} className="no-sides-padding">
    <Accordion defaultActiveKey="0">
      <Card className="border-0 box-shadow">
          <Accordion.Toggle as={Card.Header} eventKey="0" className="bg-white cursor-pointer" onClick={handleFirstChangeIcon}>
            <div>
              <h4 className="mb-1 px-3">Requisitos para el trámite de Modificación - Persona Humana</h4>
              <small className="text-muted px-3">Aquí usted podrá consultar los requisitos para el trámite de modificación para personas humanas</small>
              <div className="text-right">
                <FontAwesomeIcon className=" mr-0" icon={changeFirstIcon === faSortDown ? faSortUp : faSortDown} size="md"></FontAwesomeIcon>
              </div>
            </div>
            </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <Card>
                <Card.Header as="h5">Personas Humanas</Card.Header>
                <Card.Body className="px-3 px-md-6">
                  <Disclaimer tramiteName={"modificación"} />

                <ol type="1" className="pl-3">
                  <li>Ampliación de rubro de actividad:</li>
                    <ol type="a" className="mb-2">
                      <li>Constancia de AFIP donde se visualice la nueva actividad.</li>
                      <li>Constancia de inscripción ante ATM (Misiones o Convenio Multilateral) donde se visualice la nueva actividad.</li>
                      <li>Habilitación municipal (deberá subir en el campo de habilitación municipal la nueva constancia donde se
                        refleje el rubro incorporado. No se puede modificar con ello la fecha de vencimiento de la inscripción correspondiente).
                      </li>
                    </ol>
                  <li className="mb-2">En el caso que modifique su apoderado/representante adjuntar certificado de poder, fotocopia de DNI y certificado de libre deuda alimentaria.</li>
                  <li className="mb-2">En el caso del fallecimiento de una persona física, donde la sucesión continua con el desarrollo comercial del
                    causante deberá adjuntar:
                  </li>
                    <ol type="a">
                      <li>la nueva constancia de inscripción ante AFIP, ATM o Convenio Multilateral y la Habilitación Municipal correspondiente,
                      con el cambio de titular.
                      </li>
                      <li>Adjuntar la designación del Administrador judicial de la sucesión.</li>
                    </ol>
                </ol>

                </Card.Body>
              </Card>
            </Card.Body>
          </Accordion.Collapse>
        </Card>

        <Card className="border-0 box-shadow">
          <Accordion.Toggle as={Card.Header} eventKey="1" className="bg-white cursor-pointer" onClick={handleSecondChangeIcon}>
            <div>
              <h4 className="mb-1 px-3">Requisitos para el trámite de Modificación - Persona Jurídica</h4>
              <small className="text-muted px-3">Aquí usted podrá consultar los requisitos para el trámite de modificación para personas Jurídicas</small>
              <div className="text-right">
                <FontAwesomeIcon className=" mr-0" icon={changeSecondIcon === faSortDown ? faSortUp : faSortDown} size="md"></FontAwesomeIcon>
              </div>
            </div>
          </Accordion.Toggle>

            <Accordion.Collapse eventKey="1">
            <Card.Body>
              <Card>
                <Card.Header as="h5">Personas Jurídicas</Card.Header>
                <Card.Body className="px-3 px-md-6">
                  <Disclaimer />

                <ol type="1" className="pl-3">
                  <li>Ampliación de rubro de actividad:</li>
                    <ol type="a" className="mb-2">
                      <li>Constancia de AFIP donde se visualice la nueva actividad.</li>
                      <li>Constancia de inscripción ante ATM (Misiones o Convenio Multilateral) donde se visualice la nueva actividad.</li>
                      <li>Habilitación municipal (deberá subir en el campo de habilitación municipal la nueva constancia donde se
                        refleje el rubro incorporado. No se puede modificar con ello la fecha de vencimiento de la inscripción correspondiente).
                      </li>
                    </ol>
                  <li className="mb-2">2.	Modificación de datos registrales: Casos posibles</li>
                    <ol type="a">
                      <li>Acta o instrumento legal que indique la modificación de la composición de los integrantes de la sociedad o del
                        órgano de administración/dirección.
                      </li>
                      <li>En el caso que modifique su apoderado/representante adjuntar certificado de poder, fotocopia de DNI y certificado de libre deuda alimentaria.</li>
                      <li>Si se trata de una persona jurídica que modifica su razón social, adjuntar el instrumento legal que aprueba
                        dicho cambio - modificación al contrato social -.
                      </li>
                    </ol>
                </ol>
                </Card.Body>
              </Card>
            </Card.Body>
          </Accordion.Collapse>
        </Card>

      </Accordion>
    </Col>
  )
}

export default ModificationRequirements;
