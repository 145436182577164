import React from 'react'
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import Navigation from 'Src/components/commons/navigation';


const Header = () => {

  return (
    <div class="col-md-12 px-0">
        <div class="well inline-headers float-sm-right">
        </div>
        <Navigation></Navigation>
    </div>
  )
}

const mapStateToProps = state => {
    return {
    };
}

const mapDispatchToProps = dispatch => {
    return {
        goToLogin: (URL) => dispatch(push(URL)),
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Header);