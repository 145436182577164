import React, {Component} from 'react'

import Header from './header'
import Footer from './footer'
import Navigation from 'Src/components/commons/navigation';


export default class Layout extends Component {
    render() {
        return (
            <div className="container-fluid p-0">
                <Header />
                {this.props.children}
                <Footer />
            </div>
        )
    }
}