import React from 'react';
import { Modal, Button, ListGroup } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

import ReactGA from "react-ga";

import {env} from "../env"
let { ProviderDetailsTexts, modalDetailsTextSnippets } = env;

const modalDetails = (props) => {

  let itemData = props.detailsData;

  const { textSnippets } = ProviderDetailsTexts;

  const { activitiesById } = props.globalProps;

  const realAddressReduce = (realAddress) =>
  realAddress ? realAddress.reduce( (prevVal, nextVal) => (prevVal && prevVal.name ? prevVal.name+ " " : prevVal) + nextVal ) : realAddress;

  const legalAddressReduce = (legalAddress) =>
  legalAddress ? legalAddress.reduce( (prevVal, nextVal) => (prevVal && prevVal.name ? prevVal.name+ " " : prevVal) + nextVal ) : legalAddress;

  const parseStartDate = (startDate) => startDate ? new Date(startDate).toLocaleDateString() : " ";

  const parseEndDate = (endDate) => endDate ? new Date(endDate).toLocaleDateString() : " ";

  const urlConsta = () => {
      ReactGA.event({
          category: 'Constancia de inscripción',
          action: 'Descargar',
          value: 3
      });

      var a = document.createElement('a');
        a.href= itemData.registrationReportUrl;
        a.target = '_blank';
        document.body.appendChild(a);
        a.click();
  }

  return (
    <Modal  {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {modalDetailsTextSnippets.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ListGroup className="col-lg-12 d-inline-flex">

          <ListGroup.Item className="border-0 font-weight-500">
            {textSnippets.cuit} {itemData.cuit}
          </ListGroup.Item>

          <ListGroup.Item className="border-0 font-weight-500">
            {textSnippets.nombre} {itemData.companyName}
          </ListGroup.Item>

          <ListGroup.Item className="border-0 font-weight-500">
            {textSnippets.status} {itemData.status ? props.evaluateStatus(itemData.status) : " " }
          </ListGroup.Item>

          <ListGroup.Item className="border-0 font-weight-500">
            {textSnippets.nombreFantasia} {itemData.fantasyName ? itemData.fantasyName : " "}
          </ListGroup.Item>

          <ListGroup.Item className="border-0 font-weight-500">
            {textSnippets.DomicilioRealLegal}
            {
            itemData.realAddress
            ?
              realAddressReduce(itemData.realAddress)
            : null
            }
          </ListGroup.Item>

          <ListGroup.Item className="border-0 font-weight-500">
            {textSnippets.DomicilioLegalMisiones}
            {
            itemData.legalAddress
            ?
              legalAddressReduce(itemData.legalAddress)
            : null
            }
          </ListGroup.Item>

          <ListGroup.Item className="border-0 font-weight-500">
            {textSnippets.mail} {itemData.email ? itemData.email : " "}
          </ListGroup.Item>

          <ListGroup.Item className="border-0 font-weight-500">
            {textSnippets.telefono} {itemData.phoneNumber ? itemData.phoneNumber : " "}
          </ListGroup.Item>

          <ListGroup.Item className="border-0 font-weight-500">
            {textSnippets.actividades}
            {
              Array.isArray(activitiesById) && activitiesById.length > 0
              ?
                activitiesById.map(activity =>

                  <ListGroup.Item className="border-0 no-sides-padding no-vertical-padding">
                  <FontAwesomeIcon icon={faCircle} size="xs" className="list-indicator"></FontAwesomeIcon>
                    {activity.name}
                  </ListGroup.Item>
                )
              : " "
            }
          </ListGroup.Item>

          <ListGroup.Item className="border-0 font-weight-500">
            {textSnippets.fechaInicio} {itemData.startDate ? parseStartDate(itemData.startDate) : null}
          </ListGroup.Item>

          <ListGroup.Item className="border-0 font-weight-500">
            {textSnippets.fechaVencimiento} {itemData.endDate ? parseEndDate(itemData.endDate) : null}
          </ListGroup.Item>

          <ListGroup.Item className="border-0 font-weight-500">
            {
              itemData.registrationReportUrl
              ?
                <>
                  <span>{textSnippets.descarga}</span>
                  <a href="javascript:void(0)" onClick={urlConsta} download>Click aquí</a>
                </>
              : null
            }
          </ListGroup.Item>
        </ListGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn btn-danger" onClick={props.onHide}>{modalDetailsTextSnippets.closeModalButtonText}</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default modalDetails;
