import React from 'react';
import { Form } from 'react-bootstrap';

const SubItemSelect = (props) => {

    return (
        <Form.Group controlId="opciones de subRubro" className="col-lg-5 offset-lg-2 d-inline-block mb-3" variant="danger">
            <Form.Label>Seleccione el Sub Rubro:</Form.Label>
            <Form.Control as="select" onChange={(e) => props.selectHandler(e) } name={"subRubro"} 
                ref={props.useFormState.register( {required: Boolean(props.itemSelectionDependency) })} >
                
                <option></option>
                { props.subItemsInfo && Array.isArray(props.subItemsInfo.subItemsList)
                  && props.subItemsInfo.subItemsList.length > 0 ? props.subItemsInfo.subItemsList.map(item => (
                    <option key={item.id} value={item.id}>{item.name}</option>
                )): null }

            </Form.Control>
            {props.useFormState.errors.subRubro ? props.useFormState.errors.subRubro.type === "required" && "El campo sub Rubro es requerido" : null}
        </Form.Group>
    )
}

export default SubItemSelect;