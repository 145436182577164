import React, { useState, useEffect } from 'react';

import ErrorEmptyProviderOrderedList from '../ErrorEmptyProviderOrderedList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faSadTear } from '@fortawesome/free-solid-svg-icons';

const HashResultMsg = (props) => {

  const { isFetching, hashResponse, hashValue, hashRespReset, hashIsFetched  } = props.Message;

  const [isFirstRender, setIsFirstRender ] = useState(false);

  let parseEndDate = endDate => new Date(endDate).toLocaleDateString();

  useEffect(()=>{
    if(!hashResponse && !hashValue) {
      setIsFirstRender(true);
    }else{
      setIsFirstRender(false);
    }

    if(hashRespReset && !hashResponse && hashValue){
      setIsFirstRender(true);
    }else{
      setIsFirstRender(false);
    }

  },[isFirstRender, hashRespReset])

  return (
    <main>
      <ul class="gradient-list">
      
        {
            hashResponse && hashValue && hashIsFetched
          ? 
            <React.Fragment>
              <span className={`${isFetching ? 
                "col-12 no-sides-padding d-inline-block list-item-loading-text-overhead" 
                : "d-none"}`}>
                  Cargando...
              </span>

              <li className={`${isFetching ? "loading-blur" : ""}`}>
                <span><FontAwesomeIcon icon={faFileAlt} size="lg" className="fileIcon"></FontAwesomeIcon></span>
                <span class="col-lg-12 no-sides-padding d-inline-block">
                  La vigencia del proveedor {hashResponse.companyName} es válida hasta el {parseEndDate(hashResponse.endDate) } 
                </span> 
              </li>
            </React.Fragment>
          : 
            !hashResponse && hashValue && !isFirstRender && hashIsFetched
          ?
              <ErrorEmptyProviderOrderedList icon={faSadTear} isFromHashResponse={true} />
          :
              null
        }
      </ul>
    </main>
  )
}

export default HashResultMsg
