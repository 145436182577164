export const LIST_DATA_FETCHED = "LIST_DATA_FETCHED";
export const FETCHING_LIST_DATA = "IS_FETCHING_LIST_DATA";
export const FETCHING_LIST_DATA_FAILURE = "FETCHING_LIST_DATA_FAILURE";
export const ITEMS_PER_PAGE_SELECTED = "ITEMS_PER_PAGE_SELECTED";
export const NUMBER_PAGE_SELECTED = "NUMBER_PAGE_SELECTED";
export const USER_FILTER_DATA = "USER_FILTER_DATA";
export const ACTIVITIES_BY_ID = "ACTIVITIES_BY_ID";
export const ONLY_VALIDS = "ONLY_VALIDS";
export const HASH_RESPONSE = "HASH_RESPONSE";
export const HASHVALUE = "HASHVALUE";
export const RESET_HASH_RESPONSE = "RESET_HASH_RESPONSE";
export const RESET_PROVIDERS_LIST_RESULTS = "RESET_PROVIDERS_LIST_RESULTS";

export const SET_ITEMS_TO_SELECT = "SET_ITEMS_TO_SELECT";
export const SET_PROVIDER_TRANSACT_TYPES = "SET_PROVIDER_TRANSACT_TYPES";
export const SET_SUB_ITEMS_TO_SELECT = "SET_SUB_ITEMS_TO_SELECT";

export const GET_STATUS_API_REQUEST =  "GET_STATUS_API_REQUEST";
export const GET_STATUS_API_SUCCESS =  "GET_STATUS_API_SUCCESS";
export const GET_STATUS_API_FRAILURE =  "GET_STATUS_API_FRAILURE";
