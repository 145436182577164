import React from 'react';
import { Form } from 'react-bootstrap';

const ItemSelect = (props) => {

    return (
        <Form.Group controlId="Opciones de Rubro" className="col-lg-5 d-inline-block mb-3" variant="danger">
            <Form.Label>Seleccione el Rubro:</Form.Label>
            <Form.Control as="select" onChange={(e) => props.selectHandler(e) } name={"rubro"} ref={props.useFormState.register} >

                <option></option>
                { props.itemsInfo?.itemsToSelect ? props.itemsInfo.itemsToSelect.map(item => (
                    <option key={item.id}  value={item.id} >{item.name}</option>
                )): null }

            </Form.Control>
        </Form.Group>
    )
}

export default ItemSelect;