import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { searchByHash, setHashValue, resetHashResponse, cleanProvidersResults } from '../redux/actions/actions';
import { hashValue, hashResponse, isFetching, hashIsFetched } from '../redux/reducers/index';

import { Container, Col } from 'react-bootstrap';
import HashVerificationComponent from '../components/hashVerificationComponent';
import HashResultMsg from '../components/commons/HashResultMsg';

const RegistrationProof = (props) => {

  const { setHashValue, searchByHash, cleanProvidersResults, hashValue, resetHashResponse, hashIsFetched  } = props;

  const [hashRespReset, setHashRespReset ] = useState(false);

  const queryParam = window.location.search;
  const urlParams = new URLSearchParams(queryParam);
  const valueHash = urlParams.get("codigoVerificador");

  useEffect(()=>{
    if(valueHash) setHashValue(valueHash) && searchHashHandler(valueHash);

  },[])

  const inputValueHandler = (e) => { setHashValue(e.target.value), setHashRespReset(false)};

  const searchHashHandler = (val) => { searchByHash(val), setHashRespReset(false) };

  const cleanHashValue = () => { setHashValue(null), resetHashResponse(), setHashRespReset(true)};

  const cleanListResults = (e) => { cleanProvidersResults(), e.preventDefault() };

  return (
    <Container className="py-5 mx-auto no-sides-padding">
      <Col md={10} xs={12} lg={12} xl={12} className="no-sides-padding">
        <HashVerificationComponent handlers={{inputValueHandler, searchHashHandler, cleanHashValue, cleanListResults }} 
          hashValue={hashValue} queryExist={queryParam} >
        </HashVerificationComponent>
      </Col>

      <HashResultMsg Message={{...props, hashRespReset, hashIsFetched}}></HashResultMsg>
    </Container>
  )
};

const mapStateToProps = state => {
  return {
    hashValue: hashValue(state),
    hashResponse: hashResponse(state),
    isFetching: isFetching(state),
    hashIsFetched: hashIsFetched(state)
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setHashValue: val => dispatch( setHashValue(val) ),
    searchByHash: val => dispatch( searchByHash(val) ),
    resetHashResponse: () => dispatch( resetHashResponse() ),
    cleanProvidersResults: () => dispatch( cleanProvidersResults() )
  }
 }

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationProof);
