/*Packages */
import React, {useState} from 'react';

/* Components */
import FilterForm from './FilterForms';

/* Styled components */
import { Card } from 'react-bootstrap';

const ItemSelectionCardBody = (props) => {

  const { cleanResults } = props;

  const [ cuitValue, setcuitValue ] = useState(null);
  const [ companyName, setCompanyName ] = useState(null);
  const [ vigenciaCheck, setVigenciaCheck ] = useState(false);
  const [ tramiteStatus, setTramiteStatus ] = useState(false);
  const [ bajaStatus, setBajaStatus ] = useState(false);

  let itemSelectHandler = (e) => {
    props.itemsInfo.setItemSelectedId(e.target.value);
  }

  let subItemSelectHandler = (e) => {
    props.subItemsInfo.setSubItemSelectedId(e.target.value);
  }

  const cleanListResults = (e) => { cleanResults(), e.preventDefault() };


  let cleanForm = (e) => {
    setcuitValue("");
    setCompanyName("");
    props.itemsInfo.setItemSelectedId(undefined);
    setTramiteStatus(false);
    setBajaStatus(false);
    setVigenciaCheck(false);
    cleanListResults(e)
    let form = document.getElementById("filterForm");
    form ? form.reset() : null;
    e.preventDefault();
  }

    return (
        <Card.Body>
           
            <FilterForm globalProps={props.globalProps} setFilters={props.setFilters}
              itemsInfo={props.itemsInfo} subItemsInfo={props.subItemsInfo} useFormState={props.useFormState} 
                setValues={{setcuitValue, setCompanyName, setVigenciaCheck, setTramiteStatus, setBajaStatus} }
                  values={{cuitValue, companyName, vigenciaCheck, bajaStatus, tramiteStatus}} cleanValues={cleanForm}
                    eventHandlers={{itemSelectHandler, subItemSelectHandler}} onSubmit={props.onSubmit}
            >
            </FilterForm>
        </Card.Body>
    )
}

export default ItemSelectionCardBody;