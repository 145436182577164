import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';

import ProviderListCard from './ProviderListCard';

import { Container, Col, Card } from 'react-bootstrap';
import { PROVIDERS_QUERY } from 'Src/routes';

/* Components */ 
import ItemSelectionCardBody from '../components/ItemSelectionCardBody';

import { getItems, getSubItems, setOnlyValids, setFilterData, asyncFetchData, 
  setPageSelected, cleanProvidersResults, getActivities } from '../redux/actions/actions';

import { itemsToSelect, subItemsToSelect, filterData, itemsPerPage, selectedPage, listResults, isFetching, activitiesById } from '../redux/reducers/index';

import {env} from "../env"
let { initialState, providerStatusCodes } = env;

const ProvidersQuery = props => {

  const { getItemsToSelect, itemsToSelect, getSubItems, subItemsList, filterData, itemsPerPage,
    setOnlyValids, setFilterData, asyncFetchData, setPageSelected, selectedPage, cleanProvidersResults } = props;

  const { register, handleSubmit, errors } = useForm();


  /* filterFormContainer CONTAINER STATES */
  const [ itemSelectedId, setItemSelectedId ] = useState();
  const [ subItemSelectedId, setSubItemSelectedId ] = useState();

  const { soloVigentes, enTramiteSafi, enRevisionSafi, pendiente, baja } = providerStatusCodes?.status;

  useEffect( () => {
    !itemsToSelect ? getItemsToSelect() : null;

    itemSelectedId ? getSubItems(itemSelectedId) : null;
  
    }, [itemSelectedId, itemsToSelect]);

    const searchOnPaginationChange = () => asyncFetchData(selectedPage, itemsPerPage, filterData);

    useEffect(()=>{
      if(selectedPage && selectedPage !== initialState.selectedPage && itemsPerPage){
        searchOnPaginationChange();
      }
   }, [selectedPage, itemsPerPage])

    /**
   * 
   * @param {String} page 
   * @param {String} itemsPerPage 
   * @param {Object} params // the updated Form Data by the user to call the listProvider API filter.
   */
  let searchData = (page, itemsPerPage, params) => {
    asyncFetchData(page, itemsPerPage, params);
  }

    const onSubmit = (data) => validateFormData(data);

  let validateFormData = (data) => {

    let statusIds = []

      const vigencyCheck = (data) => {

        data.vigencia && data.vigencia === "on" ? setOnlyValids(true) && statusIds.push(soloVigentes?.code) : setOnlyValids(false);

        data.tramite && data.tramite === "on" ? statusIds = enTramiteSafi?.code : null;
        
        data.baja && data.baja === "on" ? statusIds = baja?.code : null;
      }
      vigencyCheck(data);

    let dataFiltersSelected = {
      "cuit": data.cuit.replace("-", "").replace("-", ""),
      "companyName": data.razon_social.toUpperCase(),
      "economicActivityTypeId": data.rubro,
      "economicActivitySubTypeId": data.subRubro,
      "statusIds": statusIds && statusIds.length && statusIds.length > 0 ? statusIds : ""
    }

    setFilterData(dataFiltersSelected);
    // props.resetHashResponse();

    if( filterData && dataFiltersSelected.cuit !== "" && filterData.cuit !== dataFiltersSelected.cuit ){
      searchData("1", initialState.itemsPerPage, dataFiltersSelected);
    }
    if(filterData && filterData.companyName !== dataFiltersSelected.companyName )
      searchData("1", initialState.itemsPerPage, dataFiltersSelected);

    if(!dataFiltersSelected.cuit && !dataFiltersSelected.companyName && !dataFiltersSelected.economicActivitySubTypeId ){
      setPageSelected("1")
      searchData("1", initialState.itemsPerPage, dataFiltersSelected);
    }
    if(filterData && dataFiltersSelected.statusIds && dataFiltersSelected.statusIds.length ){
      setPageSelected("1")
      searchData("1", initialState.itemsPerPage, dataFiltersSelected);
    }else{
      searchData(selectedPage, itemsPerPage, dataFiltersSelected);
    }
  } 


  return (
    <Container className="py-5 mx-auto no-sides-padding">
      <Col md={10} xs={12} lg={12} xl={12} className="no-sides-padding">
          <Card className="border-0 box-shadow">
            <Card.Header eventKey="0" className="bg-white">
              <h4 className="mb-1 px-3">Consulta de Proveedores</h4>
              <small className="text-muted px-3">Aquí usted podrá consultar el listado de los Proveedores habilitados</small>
            </Card.Header>

              <Card.Body className="px-3 px-md-6">
                <Card>
                  <Card.Header as="h5">Filtro de busqueda</Card.Header> 
                    <ItemSelectionCardBody globalProps={props.globalProps} itemsInfo={ {itemsToSelect, setItemSelectedId, itemSelectedId }}
                        subItemsInfo={ {subItemsList, setSubItemSelectedId, subItemSelectedId} } cleanResults={cleanProvidersResults}
                        useFormState={{ register, handleSubmit, errors }} onSubmit={onSubmit} >
                    </ItemSelectionCardBody>
                </Card>
              </Card.Body>
          </Card>

      </Col>

      <ProviderListCard globalProps={props} layout={PROVIDERS_QUERY}></ProviderListCard>
    </Container>
  )
}

const mapStateToProps = state => {
  return {
    itemsToSelect: itemsToSelect(state),
    subItemsList: subItemsToSelect(state),
    filterData: filterData(state),
    itemsPerPage: itemsPerPage(state),
    selectedPage: selectedPage(state),
    listResults: listResults(state),
    isFetching: isFetching(state),
    activitiesById: activitiesById(state),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getItemsToSelect : () => dispatch( getItems() ),
    getSubItems: (itemId) => dispatch( getSubItems(itemId) ),
    setOnlyValids: (val) => dispatch(setOnlyValids(val)),
    setFilterData: (filterData) => dispatch(setFilterData(filterData)),
    asyncFetchData: (selectedPage, itemsPerPage, filterData) => dispatch(asyncFetchData(selectedPage, itemsPerPage, filterData)),
    setPageSelected: (PageSelected) => dispatch(setPageSelected(PageSelected)),
    cleanProvidersResults: () => dispatch( cleanProvidersResults() ),
    getActivitiesById: (providerId) => dispatch(getActivities(providerId)),

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProvidersQuery);
