const config = {
    urlBackend : "http://localhost:5000",
    appName : "Portal de Proveedores de la Provincia de Misiones",
    version : "V2.2.3",
    savingPeriod : 1000,
    cartelMantenimientoVisible : false,
    gAnalyticCode : 'UA-183069998-1',
    gAnalyticDebug : true,
    cartelMantenimientoText : `El día de hoy nos encontraremos haciendo mantenimiento de los trámites del
    Registro Oficial de Proveedores del Estado de la Provincia de Misiones. Es posible que las consultas y acciones provistas
    en este portal pueden no funcionar correctamente. Le solicitamos que por favor, intente nuevamente más tarde. Muchas gracias.`,
    initialState : {
        isFetching: false,
        result: null,
        errorFetching: false,
        itemsPerPage: "10",
        selectedPage: "1",
        pageCount: null,
        hasMore: null,
        filterData: null,
        activitiesById: null,
        onlyValids: false,
        hashValue: null,
        hashResponse: null,
        itemsToSelect: null,
        subItemsToSelect: null,
        providerTransactTypes: null,
        hashIsFetched: false,
        statusAfip: [],
    },
    providerStatusCodes : {
    status: {
        soloVigentes: {
        code: 4,
        description: "Aceptado",
        },
        enTramiteSafi: {
        code: [2, 3],
        description: "En trámite",
        },
        enRevisionSafi: {
        code: 3,
        description: "En trámite",
        },
        pendiente: {
        code: 1,
        description: "Pendiente",
        },
        baja: {
        code: [5, 6, 7],
        description: "Denegado/Dado de baja",
        },
    },
    },
    ProviderDetailsTexts : {
    textSnippets: {
        // "ID:" :  "ID:",
        cuit: "CUIT:",
        nombre: "Apellido Nombre / Razón Social:",
        status: "status:",
        nombreFantasia: "Nombre de Fantasia:",
        DomicilioRealLegal: "Domicilio Real / Legal: ",
        DomicilioLegalMisiones: "Domicilio Legal en Misiones: ",
        mail: "E-Mail:",
        telefono: "Teléfono:",
        actividades: "Actividades Económicas:",
        fechaInicio: "Fecha de Inicio de Actividades:",
        fechaVencimiento: "Fecha de Vencimiento:",
        descarga: "Descargar constancia: ",
    },
    },
    modalDetailsTextSnippets : {
        title: "Detalle proveedor",
        closeModalButtonText: "Cerrar",
    },
    paginationNumberConfig : {
        numberToShow: 5,
    },
    resultsCardsTexts : {
    codeVerificationErrorMsg:
        "ATENCIÓN: La Constancia de Inscripción está vencida o no es válida.",
    listResultErrorMsg:
        "No encontramos resultados para tu busqueda. Intenta de nuevo.",
    },
    ENVIRONMENT__NAME: "LOCAL" //DEVELOPMENT|STAGE|PREPRODUCTION|PRODUCTION
}

export const env = { ...config, ...window['env'] }

