/*Packages */
import React from 'react';
import InputMask from "react-input-mask";

/* Components */
import ItemSelect from './ItemSelect';
import SubItemSelect from './SubItemSelect';

/* Styled components */
import { Card, Col, Form, FormControl, Button, ButtonToolbar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

const FilterForm = (props) => {

    let useFormState = props.useFormState;

    let CUITmask = [/[1-9]/,/\d/,'-',/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,'-',/\d/];

    return(
      <Form className="mb-3" id="filterForm" onSubmit={useFormState.handleSubmit(props.onSubmit)}>
        <ButtonToolbar>
            <ItemSelect itemsInfo={props.itemsInfo } useFormState={props.useFormState} selectHandler={props.eventHandlers.itemSelectHandler} />
            <SubItemSelect selectHandler={props.eventHandlers.subItemSelectHandler} useFormState={props.useFormState}
                itemSelectionDependency={props.itemsInfo.itemSelectedId} subItemsInfo={props.subItemsInfo}
            />
        </ButtonToolbar>

        <Col className="mb-3 no-sides-padding d-flex flex-wrap">
            <Form.Group className="col-lg-5">
                <Form.Label>Razón Social:</Form.Label>
                    <FormControl name="razon_social" aria-describedby="Razón Social"
                      ref={useFormState.register} value={props.values.companyName} onChange={ (e) => props.setValues.setCompanyName(e.target.value)} />
            </Form.Group>
            <Form.Group className="col-lg-5 offset-lg-2">
                <Form.Label>CUIT:</Form.Label>

                    <InputMask alwaysShowMask={false} maskPlaceholder={'XX-XXXXXXXX-X'} mask={CUITmask} 
                        inputRef={useFormState.register} value={props.values.cuitValue} onChange={ (e) => props.setValues.setcuitValue(e.target.value)} >
                        <FormControl name="cuit" 
                          ref={ 
                            useFormState.register({
                              validate: {
                                pattern: /\b(20|23|24|27|30|33|34)(\D)?[0-9]{8}(\D)?[0-9]/g }
                              }
                          )} 
                        />
                    </InputMask>
                        {/*Input Error Messages"*/}
                    {useFormState.errors.cuit && useFormState.errors.cuit.type === "pattern" && "El CUIT introducido no es válido"}
            </Form.Group>
        </Col>

        <Col className="mb-3 no-sides-padding d-flex flex-wrap">
          <Form.Group className="col-lg-8">

            
              <Form.Label className="col-lg-12 no-sides-padding">Estado de la inscripción:</Form.Label>

              <Form.Group className="col-lg-3 no-sides-padding d-inline-block cursor-pointer" onClick={ () => props.setValues.setVigenciaCheck(!props.values.vigenciaCheck) }>
                <Form.Check checked={props.values.vigenciaCheck} name="vigencia" ref={useFormState.register} className="px-3 cursor-pointer" inline 
                  label="Solo vigentes" type="radio" 
                />
              </Form.Group> 

            <Form.Group className="col-lg-3 no-sides-padding d-inline-block cursor-pointer" onClick={ () => props.setValues.setTramiteStatus(!props.values.tramiteStatus) } >
              <Form.Check checked={props.values.tramiteStatus} name="tramite" ref={useFormState.register} className="px-3 cursor-pointer" inline 
                label="En trámite" type="radio" 
              />
            </Form.Group>

            <Form.Group className="col-lg-5 no-sides-padding d-inline-block cursor-pointer" onClick={ () => props.setValues.setBajaStatus(!props.values.bajaStatus) }  >
              <Form.Check name="baja" ref={useFormState.register} className="px-3" inline label="Dado de baja o Denegado" type="radio" 
                  checked={!props.values.vigenciaCheck && !props.values.tramiteStatus && props.values.bajaStatus}
              />
            </Form.Group>
          </Form.Group>
        </Col>
        <Card.Footer className="text-muted d-flex bg-white justify-content-between">
          <Button type="submit" className="btn btn-danger general-hover-animation">
            Consultar{<span> {">"}</span>}
          </Button>

          <button class="btn btn-secondary px-4 general-hover-animation" onClick={(e) => props.cleanValues(e)}>Limpiar 
            <FontAwesomeIcon className="mx-1" icon={faTrashAlt} size="sm"></FontAwesomeIcon>
          </button>
        </Card.Footer>
      </Form>
    )
}

export default FilterForm;