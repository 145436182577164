import React, { useState, useContext, Children } from "react";

import Markdown from "markdown-to-jsx";

import { Accordion, Col, Card } from "react-bootstrap";
import AccordionContext from "react-bootstrap/AccordionContext";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";
import { faSortUp } from "@fortawesome/free-solid-svg-icons";

import { faqData } from "../data/faq-data";



function ContextAwareToggle({ children, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext);
  
  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey),
  );
  
  const isCurrentEventKey = currentEventKey === eventKey;
  
  return (
    <Accordion.Toggle
    as={Card.Header}
    className="bg-white cursor-pointer"
    onClick={decoratedOnClick}
    eventKey={eventKey}
  >
    <h5 className="mb-1 px-3">{children}</h5>
    <div className="text-right">
      <FontAwesomeIcon
        className="mr-0"
        icon={isCurrentEventKey ? faSortUp : faSortDown}
        size="md"
      ></FontAwesomeIcon>
    </div>
  </Accordion.Toggle>
  );
}

const Faq = () => {
  return (
    <Col md={10} xs={12} lg={12} xl={12} className="no-sides-padding">
      <Accordion defaultActiveKey={1}>
        {faqData.map((item, key) => (
          <Card className="border-0 box-shadow">
            <ContextAwareToggle eventKey={key + 1}>
              {item.pregunta}
            </ContextAwareToggle>
            <Accordion.Collapse eventKey={key + 1}>
              <Card.Body>
                <Card>
                  {item.titulo && (
                    <Card.Header as="h5">{item.titulo}</Card.Header>
                  )}
                  <Card.Body className="px-3 px-md-6">
                    <Markdown>{item.respuesta}</Markdown>
                  </Card.Body>
                </Card>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        ))}
      </Accordion>
    </Col>
  );
};

export default Faq;
