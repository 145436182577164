import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faSadTear } from '@fortawesome/free-solid-svg-icons';


const ListHeader = () => (
  <React.Fragment>
           <li>
          <span><FontAwesomeIcon icon={faFileAlt} size="lg" className="fileIcon"></FontAwesomeIcon></span>
          <span class="col-lg-2 no-sides-padding d-inline-block">CUIT</span> 
          <span class="col-lg-4 no-sides-padding d-inline-block">Razón Social</span> 
          <span class="col-lg-5 no-sides-padding d-inline-block">Localidad</span > 
          <span class="col-lg-1 no-sides-padding d-inline-block">Vigencia</span> 
        </li>
      </React.Fragment>
)

export default ListHeader;