import React from 'react';

import { Nav, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { REQUISITO_INSCRIPCION_TRAMITES_URI, REQUISITO_ACTUALIZACION_TRAMITES_URI, REQUISITO_MODIFICACION_TRAMITES_URI,
  REQUISITO_REINSCRIPCION_TRAMITES_URI, PROVIDERS_QUERY, REGISTRATION_PROOF, DOWNLOAD_INSTRUCTIONS, DASHBOARD_URI, FAQ_URI, PROVIDER_SYSTEM } from 'Src/routes';

const Menus = () => {

  const customStyles = {
    width: "305px",
    color: "#fff"
  }
    return (
      <Nav className="mr-auto font-weight-bold pl-2">
          <Nav.Item>
            <Nav.Link className="nav-level-1 mr-1">
              <Link className="color-white" to={DASHBOARD_URI}>Inicio</Link>
            </Nav.Link>
          </Nav.Item>

          <Nav.Item>
            <Nav.Link className="nav-level-1 mr-1">
              <Link className="color-white" to={PROVIDERS_QUERY}>Consulta de Proveedores</Link>
            </Nav.Link>
          </Nav.Item>

          <Nav.Item>
            <Nav.Link className="nav-level-1 mr-1">
              <Link className="color-white" to={REGISTRATION_PROOF}>Verificación de Constancia</Link>
            </Nav.Link>
          </Nav.Item>

          <NavDropdown className="color-white mr-1" style={customStyles} title="Requisitos de Trámites" id="nav-dropdown">
                <NavDropdown.Item eventKey="1.1">
                  <Nav.Link className="nav-level-1 py-0">
                    <Link className="color-black" to={REQUISITO_INSCRIPCION_TRAMITES_URI}>Trámite de Inscripción</Link>
                  </Nav.Link>
                </NavDropdown.Item>

                {/* <NavDropdown.Item eventKey="1.2">
                  <Nav.Link className="nav-level-1 py-0">
                    <Link className="color-black" to={REQUISITO_MODIFICACION_TRAMITES_URI}>Trámite de Modificación de datos</Link>
                  </Nav.Link>
                </NavDropdown.Item> */}
                
                <NavDropdown.Item eventKey="1.3">
                  <Nav.Link className="nav-level-1 py-0">
                    <Link className="color-black" to={REQUISITO_ACTUALIZACION_TRAMITES_URI}>Trámite de Actualización</Link>
                  </Nav.Link>
                </NavDropdown.Item>

                <NavDropdown.Item eventKey="1.4">
                  <Nav.Link className="nav-level-1 py-0">
                    <Link className="color-black" to={REQUISITO_REINSCRIPCION_TRAMITES_URI}>Trámite de Reinscripción</Link>
                  </Nav.Link>
                </NavDropdown.Item>
            </NavDropdown>

            <Nav.Item>
              <Nav.Link className="nav-level-1 mr-1">
                <Link className="color-white" to={FAQ_URI}>Preguntas Frecuentes</Link>
              </Nav.Link>
          </Nav.Item>
      </Nav>
    );
}

export default Menus;
