import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {instrtuctivoArray} from "../utils/utils"
import { getProviderTransactTypes } from '../redux/actions/actions';
import { providerTransactTypes, isFetching } from '../redux/reducers/index';

import { Container, Col, Card, Accordion, Nav } from 'react-bootstrap';

import ReactGA from "react-ga";

const DownloadInstructions = (props) => {
  
  const { getProviderTransactTypes, providerTransactTypes, isFetching } = props;

  useEffect( () => {
    !providerTransactTypes ? getProviderTransactTypes() : null;
    }, []);

  const btnClick = (description, url) => {
      ReactGA.event({
          category: 'Instructivos de trámites',
          action: description
      });
      console.log('metodo magico: ' , description);
   
      location.href = url;
  }

  return (
    <Container className="py-5 mx-auto no-sides-padding">
      <Col md={10} xs={12} lg={12} xl={12} className="no-sides-padding">
          <Card className="border-0 box-shadow">
            <Card.Header eventKey="0" className="bg-white">
              <h4 className="mb-1 px-3">Sistema de trámites</h4>
              <small className="text-muted px-3">Aquí usted podrá descargar el manual para el uso del sistema de trámites.</small>
            </Card.Header>
              <Card.Body className="px-3 px-md-6">
                <ul className="list-group list-group-flush">
                  {
                    instrtuctivoArray && instrtuctivoArray.length > 0 ?
                    instrtuctivoArray.map(transactType =>
                        <li className="list-group-item border-0">
                          <a class="btn btn-primary btn-argentina btn-sm mr-3"  href={transactType.url} download={transactType.title} role="button">Descargar</a>
                          {instrtuctivoArray ? ` Instructivo para trámite de ${transactType.title}`: ""}
                        </li>
                    )
                    : null
                  }
                </ul>
              </Card.Body>
          </Card>
      </Col>
    </Container>
  )
};

const mapStateToProps = state => {
  return {
    providerTransactTypes: providerTransactTypes(state),
    isFetching: isFetching(state)
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getProviderTransactTypes: () => dispatch( getProviderTransactTypes() ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DownloadInstructions);
