import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore, { history }  from './redux/store';
import './index.css';
// import App from './containers/App';
import App from "./app";
import 'bootstrap/dist/css/bootstrap.min.css';
import './providerListStyles.scss';

import { AppContainer } from 'react-hot-loader';

if (process.env.NODE_ENV !== 'production') {
  console.log('DEVELOPMENT MODE');
} else {
  console.log('PRODUCTION MODE');
}


const store = configureStore();

const render = () => {
  ReactDOM.render(
    <AppContainer>
      <Provider store={store}>
        <App history={history} />
      </Provider>
    </AppContainer>,
    document.getElementById("root")
  )
}

render()

// ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));

// Hot reloading
if (module.hot) {
  // Reload components
  module.hot.accept('./app', () => {
    render()
  })
}