import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalDetails from "./modalDetails";
import ErrorEmptyProviderOrderedList from "../components/ErrorEmptyProviderOrderedList";
import { faSadTear } from "@fortawesome/free-solid-svg-icons";
import { evaluateStatus } from "../utils/utils";

import {env} from "../env"
let { providerStatusCodes } = env;

const ProviderOrderedList = (props) => {
  const [modalShow, setModalShow] = useState(false);

  let { enTramiteSafi, enRevisionSafi } = providerStatusCodes.status;

  /**
   *
   * @param {string} id
   * The Provider Id to request the individual Economic Activities.
   */
  let requestActivities = (id) => props.globalProps.getActivitiesById(id);
  /**
   *
   * @param {Array} address
   * Concatenate the values of address Array with a space between.
   */
  let evaluateAddress = (address) =>
    address
      ? address.reduce(
          (prevVal, nextVal) =>
            (prevVal && prevVal.name ? prevVal.name + " " : prevVal) +
            " " +
            nextVal
        )
      : address;

  let parseEndDate = (endDate) =>
    endDate ? new Date(endDate).toLocaleDateString() : "-";

  return (
    <React.Fragment>
      {props.itemData.status &&
      evaluateStatus(props.itemData.status) !== "Pendiente" ? (
        <a
          onClick={() => {
            requestActivities(props.itemData.id), setModalShow(true);
          }}
        >
          <span
            className={`${
              props.globalProps.isFetching
                ? "col-12 no-sides-padding d-inline-block list-item-loading-text-overhead"
                : "d-none"
            }`}
          >
            Cargando...
          </span>

          <li
            className={`${props.globalProps.isFetching ? "loading-blur" : ""}`}
          >
            <span>
              <FontAwesomeIcon
                icon={props.icon}
                size="lg"
                className="fileIcon"
              ></FontAwesomeIcon>
            </span>
            <span class="col-lg-2 no-sides-padding d-inline-block">
              {props.itemData.cuit}
            </span>
            <span class="col-lg-4 no-sides-padding d-inline-block">
              {props.itemData.companyName}
            </span>
            <span class="col-lg-4 no-sides-padding d-inline-block">
              {evaluateAddress(props.itemData.realAddress)}
            </span>
            <span
              className="col-lg-1 no-sides-padding d-inline-block"
              style={{ float: "right" }}
            >
              {props.itemData.status !== 2
                ? parseEndDate(props.itemData.endDate)
                : "En Trámite"}{" "}
            </span>
          </li>
        </a>
      ) : (
        <ErrorEmptyProviderOrderedList icon={faSadTear} />
      )}
      <ModalDetails
        show={modalShow}
        globalProps={props.globalProps}
        detailsData={props.itemData}
        evaluateStatus={evaluateStatus}
        onHide={() => setModalShow(false)}
      ></ModalDetails>
    </React.Fragment>
  );
};

export default ProviderOrderedList;
