import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import numeral from 'numeral';

import "core-js/stable";
import "regenerator-runtime/runtime";

import routes from './routes';
import Layout from './components/commons/layout';
import Notifications from './components/commons/globalNotifications';

import es_locale from '../configs/es_locale';
numeral.register('locale', 'es', es_locale);
numeral.locale('es');

import moment from 'moment';
moment.locale('es');

// import { registerLocale } from "react-datepicker";
// import es from "date-fns/locale/es";
// registerLocale("es", es);

// import 'antd/es/tree-select/style/css';
import {env} from "./env"
let { gAnalyticCode, gAnalyticDebug } = env;
import ReactGA from "react-ga";
ReactGA.initialize(gAnalyticCode, {
    debug : gAnalyticDebug,
});

const App = ({ history }) => {
    history.listen( window => {
        ReactGA.pageview(history.location.pathname + history.location.search);
    });
  return (
    <ConnectedRouter history={history}>
        <Layout>
          <Notifications></Notifications>
          { routes }
        </Layout>
    </ConnectedRouter>
  )
}

export default App
