import PrimeraInscripcion from "../assets/InstructivoDeMisTramites-PrimeraInscripcion.pdf";
import Modificacion from "../assets/InstructivoDeMisTramites-Modificacion.pdf";
import Actuzalizacion from "../assets/InstructivoDeMisTramites-Actualizacion.pdf";
import ReInscripcion from "../assets/InstructivoDeMisTramites-Reinscripcion.pdf";
import { isNumeric } from './validationService';

import {env} from "../env"
let { providerStatusCodes } = env;

export const util_searchValueInObject = (idOfValueToSearch, Obj) => Object.keys(Obj).map(ObjKey => {
  
  if(typeof(ObjKey) !== "number" && isNaN( parseInt(ObjKey) ) ){
    if( ObjKey === idOfValueToSearch) return ObjKey[ObjKey]
    }
  else if(parseInt(ObjKey) === idOfValueToSearch) {
    return Obj[ObjKey]
  }
})


export const util_saveOnLocalStorage = (data, nameToSave) => {
  try {
      const serializedData = JSON.stringify(data);
      localStorage.setItem(`${nameToSave}`, serializedData);
  } catch(err) {
      // ignore write errors
      throw new Error(`error saving ${nameToSave} on localStorage`);
  }
};


export const util_loadFromLocalStorage = (nameToSearch) => {
  try {
      const serializedData = localStorage.getItem(`${nameToSearch}`);
      if (serializedData === null) {
          return undefined;
      }
      let serializedDataToReturn = JSON.parse(serializedData);
      
      return serializedDataToReturn;
  } catch (err) {
      return undefined;
  }
};


export const util_getTomorrowDate = () => {
  const theDate = new Date();
  theDate.setDate(theDate.getDate() + 1);
  return theDate;
};

export const parseIntIfIsNumeric = value => (value && isNumeric(value)) ? parseInt(value) : null;

let { soloVigentes, enTramiteSafi, enRevisionSafi, baja, pendiente } = providerStatusCodes.status;


export const evaluateStatus = (value) => {
  switch(value){
    case 1:{
      return pendiente.description;
    }
    case 2: {
      return enTramiteSafi.description;
    }
    case 3: {
      return enRevisionSafi.description;
    }
    case 4: {
      return soloVigentes.description;
    }
    case 5: {
      return baja.description;
    }
    case 6: {
      return baja.description;
    }
    case 7: {
      return baja.description;
    }
    default: {
      value;
    }
  }
}

export const instrtuctivoArray = [
  {
    title: "Primera Inscripcion de proveedor",
    url: PrimeraInscripcion
  },
  {
    title: "Actualizacion de proveedor",
    url: Actuzalizacion
  },
  {
    title: "Modificacion de datos de proveedor",
    url: Modificacion
  },
  {
    title: "Re inscripcion de proveedor",
    url: ReInscripcion
  }
]

export const transFormToJson = (xml) => {
  try {
    var obj = {};
    if (xml.children.length > 0) {
      for (var i = 0; i < xml.children.length; i++) {
        var item = xml.children.item(i);
        var nodeName = item.nodeName;

        if (typeof (obj[nodeName]) == "undefined") {
          obj[nodeName] = transFormToJson(item);
        } else {
          if (typeof (obj[nodeName].Push) == "undefined") {
            var old = obj[nodeName];

            obj[nodeName] = [];
            obj[nodeName].Push(old);
          }
          obj[nodeName].Push(xml2json(item));
        }
      }
    } else {
      obj = xml.textContent;
    }
    return obj;
  } catch (e) {
      console.log(e.message);
  }
};
