import React from 'react'

import {env} from "../../env"
let { appName, version } = env;

const Footer = () => {
  return (
      <footer class="row page-footer font-weight-light footer-container fixed-bottom">
          <div class="col-md-12">
              <div class="footer-copyright text-right py-1">
                  © 2021 Copyright: {appName} {version} empowered by Marandu
              </div>
          </div>
      </footer>
  );
}

export default Footer;