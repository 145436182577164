import React, { useState } from 'react';

import { Container, Accordion, Col, Card } from 'react-bootstrap';

import Disclaimer from './commons/disclaimer';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown } from '@fortawesome/free-solid-svg-icons';
import { faSortUp } from '@fortawesome/free-solid-svg-icons';

import ReactGA from "react-ga";

const UpdateRequirement = (props) => {

  const [changeFirstIcon, setChangeFirstIcon] = useState(faSortDown);
  const [changeSecondIcon, setChangeSecondIcon] = useState(faSortUp);

  const handleFirstChangeIcon = () => changeFirstIcon === faSortDown ? setChangeFirstIcon(faSortUp) : setChangeFirstIcon(faSortDown);

  const handleSecondChangeIcon = () => changeSecondIcon === faSortDown ? setChangeSecondIcon(faSortUp) : setChangeSecondIcon(faSortDown);

  ReactGA.event({
      category: 'Requisitos de trámites',
      action: 'Ver trámite de actualización'
  });

  return (
    <Col md={10} xs={12} lg={12} xl={12} className="no-sides-padding">
      <Accordion defaultActiveKey="0">
        <Card className="border-0 box-shadow">
          <Accordion.Toggle as={Card.Header} eventKey="0" className="bg-white cursor-pointer" onClick={handleFirstChangeIcon}>
            <div>
              <h4 className="mb-1 px-3">Requisitos para el trámite de Actualización - Persona Humana</h4>
              <small className="text-muted px-3">Aquí usted podrá consultar los requisitos para el trámite de actualización para personas humanas</small>
              <div className="text-right">
                <FontAwesomeIcon className=" mr-0" icon={changeFirstIcon === faSortDown ? faSortUp : faSortDown} size="md"></FontAwesomeIcon>
              </div>
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <Card>
                <Card.Header as="h5">Personas Humanas</Card.Header>
                <Card.Body className="px-3 px-md-6">
                  <Disclaimer tramiteName={"actualización"} />

                  <p>1.	Abonar tasa de $200 (Pesos: cien) - en el Formulario de ATM Tasa CGPM (920)</p>

                  <p>2.	Constancia de inscripción ante Administración Federal de Ingresos Públicos (AFIP).</p>

                  <p>3.	Constancia de inscripción en ATM ó Convenio Multilateral, vigente a la fecha de presentación
                    de la solicitud de actualización / reinscripción.</p>

                  <p>4.	Constancia de ATM, formulario SR-344 “Alta del Certificado Fiscal para contratar”. En caso de no
                    desarrollar una actividad económica en la Provincia, exteriorizar dicha situación en el formulario de actualización / reinscripción.</p>

                  <p>5.	Fotocopia autenticada de la/s habilitación/es municipal/es definitiva/s.</p>

                  <p>6.	Presentar Certificado de libre deuda emitido por el/los municipio/s en el/ los que registre actividad/es.</p>

                  <p>7.	Tener constituido el domicilio electrónico, donde serán válidas las comunicaciones cursadas (este domicilio se constituye una sola vez)</p>

                  <p>8.	En caso de poseer Representantes y/o Apoderados, fotocopia autenticada del instrumento legal, fotocopia de DNI y certificado de libre deuda alimentaria.</p>

                  <p>9.	Para el caso de actividades especiales, cuyo ejercicio requiera la autorización para funcionar de un Organismo de contralor
                    distinto a los enunciados (AFIP - ATM - Municipalidad), deberá adjuntar la misma.</p>

                  <p>10. Adjuntar certificado de libre deuda alimentaria emitido por el poder judicial. El certificado adjunto debe corresponder a la provincia donde posea su domicilio real.</p>

                  <p>11.	Formulario de solicitud de actualización / reinscripción remitido a su domicilio electrónico
                    constituido (ver punto 7), con firma autenticada.</p>

                </Card.Body>
              </Card>
            </Card.Body>
          </Accordion.Collapse>
        </Card>

        <Card className="border-0 box-shadow">
          <Accordion.Toggle as={Card.Header} eventKey="1" className="bg-white cursor-pointer" onClick={handleSecondChangeIcon}>
            <div>
              <h4 className="mb-1 px-3">Requisitos para el trámite de Actualización - Persona Jurídica</h4>
              <small className="text-muted px-3">Aquí usted podrá consultar los requisitos para el trámite de actualización para personas Jurídicas</small>
              <div className="text-right">
                <FontAwesomeIcon className=" mr-0" icon={changeSecondIcon === faSortDown ? faSortUp : faSortDown} size="md"></FontAwesomeIcon>
              </div>
            </div>
          </Accordion.Toggle>

          <Accordion.Collapse eventKey="1">
            <Card.Body>
              <Card>
                <Card.Header as="h5">Personas Jurídicas</Card.Header>
                <Card.Body className="px-3 px-md-6">
                  <Disclaimer />

                  <p>1.	Abonar tasa de $200 (Pesos: cien) - en el Formulario de ATM Tasa CGPM (920)</p>

                  <p>2.	Constancia de inscripción ante Administración Federal de Ingresos Públicos (AFIP)</p>

                  <p>3.	Constancia de inscripción en ATM ó Convenio Multilateral, vigente a la fecha de presentación de la solicitud de actualización / reinscripción.</p>

                  <p>4.	Constancia de ATM, formulario SR-344 “Alta del Certificado Fiscal para contratar”. En caso de no desarrollar
                una actividad económica en la Provincia, exteriorizar dicha situación en el formulario de actualización / reinscripción.</p>

                  <p>5.	Fotocopia autenticada de la/s habilitación/es municipal/es definitiva/s.</p>

                  <p>6.	Presentar Certificado de libre deuda emitido por el/los municipio/s en los que registre actividad pertinente.</p>

                  <p>7.	Constituir domicilio electrónico, donde serán válidas las comunicaciones cursadas (este domicilio se constituye una sola vez)</p>

                  <p>8.	En caso de poseer Representantes y/o Apoderados, fotocopia autenticada del instrumento legal, fotocopia de DNI y certificado de libre deuda alimentaria.</p>

                  <p>9.	Adjuntar certificado de libre deuda alimentaria de cada uno de los integrantes del órgano de administración/dirección,
                  emitido por el poder judicial. El certificado adjunto debe corresponder a la provincia donde el/los solicitante/s posean
                su domicilio real.</p>

                  <p>10.	Si hubieren modificaciones al contrato social – posteriores a la última presentación ante el Registro de Proveedores
                  - adjuntar fotocopia autenticada debidamente inscripta en el Registro Público, Inspección General de Justicia
                u organismo de contralor respectivo.</p>

                  <p>11.	Acta de designación de designación de autoridades del Órgano de Administración/Dirección.</p>

                  <p>12.	Fotocopia del DNI, 1º y 2º hoja, de todos los integrantes del Órgano de Administración / Dirección.</p>

                  <p>13.	Formulario de solicitud de actualización / reinscripción remitido a su domicilio electrónico constituido (ver punto 7),
                con firma autenticada.</p>

                  <p>14.	Para el caso de actividades especiales, cuyo ejercicio requiera la autorización para funcionar de un Organismo
                de contralor distinto a los enunciados (AFIP - ATM - Municipalidad), deberá adjuntar la misma</p>

                </Card.Body>
              </Card>
            </Card.Body>
          </Accordion.Collapse>
        </Card>

      </Accordion>
    </Col>
  )
}

export default UpdateRequirement;
