import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {env} from "../env"
let { resultsCardsTexts } = env;

const ErrorEmptyProviderOrderedList = (props) =>{

    return(
      <React.Fragment>
        <a >
          <li>
            <span><FontAwesomeIcon icon={props.icon} size="lg" className="fileIcon"></FontAwesomeIcon></span>
            <span class="col-12 no-sides-padding d-inline-block">
              {
                props.isFromHashResponse
                ?
                  resultsCardsTexts.codeVerificationErrorMsg
                :
                resultsCardsTexts.listResultErrorMsg
              }
              </span> 
          </li>
        </a>
      </React.Fragment>
    )
}

export default ErrorEmptyProviderOrderedList;