import React from 'react'
import { Route, Switch } from "react-router-dom";

import Dashboard from "Src/containers/Dashboard";

import ProvidersQuery from './containers/ProvidersQuery';
import RegistrationProof from './containers/RegistrationProof';
import TransactRequirementsPage from './containers/TransactRequirementsPage';
import DownloadInstructions from './containers/DownloadInstructions';

import InscriptionRequirement from './components/InscriptionRequirement';
import UpdateRequirement from './components/UpdateRequirement';
import ModificationRequirements from './components/ModificationRequirements';
import ReinscriptionRequirements from './components/ReinscriptionRequirements';
import Faq from './components/faq';

export const LOGIN_URI = '/login';
export const FORGOT_PASSWORD_URI = "/forgot-password";
export const RESET_PASSWORD_URI = "/reset-password";

export const USER_FORM_URI = '/user';
export const USER_DATA_URI = '/user-data';
export const DASHBOARD_URI = '/';

export const PROVIDERS_QUERY = `/consulta-proveedores`;
export const REGISTRATION_PROOF = `/constancia-inscripcion`;
export const DOWNLOAD_INSTRUCTIONS = `/instructivos-tramites`;
export const REQUISITO_INSCRIPCION_TRAMITES_URI = `/requisito/tramite-inscripcion`;
export const REQUISITO_ACTUALIZACION_TRAMITES_URI = `/requisito/tramite-actualizacion`;
export const REQUISITO_REINSCRIPCION_TRAMITES_URI = `/requisito/tramite-reinscripcion`;
export const REQUISITO_MODIFICACION_TRAMITES_URI = `/requisito/tramite-modificacion`;
export const FAQ_URI = `/preguntas-frecuentes`;
export const PROVIDER_SYSTEM = 'https://sistema.proveedores.misionescontaduria.com/';
const urlProceduresManual = 'https://v2.api.proveedores.misionescontaduria.com/api/v1/Files/Download/Manual_de_Usuario_GestionProveedores_0_2.pdf';

const routes = (
    <div>
      <Switch>            
        {/* <Route path={LOGIN_URI} component={ LoginPage } />
        <Route path={FORGOT_PASSWORD_URI} component={ ForgotPasswordPage } />
        <Route path={RESET_PASSWORD_URI+"/:id"} component={ ResetPasswordPage } /> */}
        <Route exact path={DASHBOARD_URI} component={ Dashboard } />
        <Route exact path={PROVIDERS_QUERY} component={ProvidersQuery} />
        <Route exact path={REGISTRATION_PROOF} component={RegistrationProof} />
        <Route exact path={REGISTRATION_PROOF+"/:codigoVerificador" } component={RegistrationProof} />
            <Route path={DOWNLOAD_INSTRUCTIONS} component={() => { window.location.href = urlProceduresManual }} />
            <Route path={PROVIDER_SYSTEM} component={() => { window.location.replace(PROVIDER_SYSTEM) }} />
        <Route exact path={REQUISITO_INSCRIPCION_TRAMITES_URI} component={ TransactRequirementsPage(InscriptionRequirement) } />
        <Route exact path={REQUISITO_ACTUALIZACION_TRAMITES_URI} component={ TransactRequirementsPage(UpdateRequirement) } />
        <Route exact path={REQUISITO_REINSCRIPCION_TRAMITES_URI} component={ TransactRequirementsPage(ReinscriptionRequirements) } />
        <Route exact path={REQUISITO_MODIFICACION_TRAMITES_URI} component={ TransactRequirementsPage(ModificationRequirements) } />
        <Route exact path={FAQ_URI} component={ TransactRequirementsPage(Faq) } />
        
        <Route render={() => (<div>Miss</div>)} />
      </Switch>
    </div>
)
  
export default routes;