import * as CONSTANTS from './constants';
import * as SERVICES from '../../utils/requests';


export const fetchDataFailure = () => {
  return {
    type: CONSTANTS.FETCHING_LIST_DATA_FAILURE
  }
}

export const fetchingData = () => {
  return {
    type: CONSTANTS.FETCHING_LIST_DATA
  }
}

export const saveDataFetched = (res) => {
  return {
    type: CONSTANTS.LIST_DATA_FETCHED,
    result: res
  }
}

export const asyncFetchData = (pageNum, pageSize, filterData) => {
  return dispatch => {
    dispatch(fetchingData());
    SERVICES.getProviderList(pageNum, pageSize, filterData)
    .then( data => dispatch(saveDataFetched(data)) )
    .catch(error => dispatch(fetchDataFailure(error)) );
  }
}

export const setItemsPerPage = (optionSelected) => {
  return {
    type: CONSTANTS.ITEMS_PER_PAGE_SELECTED,
    itemsPerPage: optionSelected
  }
}

export const setPageSelected = (numberSelected) => {
  return {
    type: CONSTANTS.NUMBER_PAGE_SELECTED,
    selectedPage: numberSelected
  }
}

export const setFilterData = (filterData) => {
  return {
    type: CONSTANTS.USER_FILTER_DATA,
    filterData: filterData
  }
}

export const saveActivitiesById = (activityData) => {
  return {
    type: CONSTANTS.ACTIVITIES_BY_ID,
    activityData: activityData
  }
}

export const getActivities = (providerId) => {
  return dispatch => {
    dispatch(fetchingData());
    SERVICES.getActivitiesById(providerId)
    .then(data => dispatch(saveActivitiesById(data)) )
    .catch(error => dispatch(fetchDataFailure(error)) );
  }
}

export const setOnlyValids = (val) => {
  return {
    type: CONSTANTS.ONLY_VALIDS,
    onlyValids: val
  }
} 

export const setHashValue = (hashValue) => {
  return {
    type: CONSTANTS.HASHVALUE,
    hashValue
  }
}

export const setHashResponse = (hashResponse) => {
  return {
    type: CONSTANTS.HASH_RESPONSE,
    hashResponse,
    hashIsFetched: true
  }
}

export const searchByHash = (val) => {
  return dispatch => {
    dispatch(fetchingData());
    SERVICES.getHashStatus(val).then(data => dispatch( setHashResponse(data.data) ) )
    .catch(error => dispatch(fetchDataFailure(error)) );
  }
}

export const resetHashResponse = () => {
  return {
    type: CONSTANTS.RESET_HASH_RESPONSE
  }
}

export const cleanProvidersResults = () => {
  return {
    type: CONSTANTS.RESET_PROVIDERS_LIST_RESULTS
  }
}

export const getItems = () => {
  return dispatch => {
    dispatch(fetchingData());
    SERVICES.getItems()
    .then(data => dispatch(setItemsToSelect(data)) )
    .catch(error => dispatch(fetchDataFailure(error)) );
  }
}

export const setItemsToSelect = (data) => {
  return {
    type: CONSTANTS.SET_ITEMS_TO_SELECT,
    payload: data      
  }
}

export const getSubItems = (itemId) => {
  return dispatch => {
    dispatch(fetchingData());
    SERVICES.getSubItems(itemId)
    .then(data => dispatch(setSubItemsToSelect(data)) )
    .catch(error => dispatch(fetchDataFailure(error)) );
  }
}

export const setSubItemsToSelect = (data) => {
  return {
    type: CONSTANTS.SET_SUB_ITEMS_TO_SELECT,
    payload: {
      data
    }
  }
}

export const getProviderTransactTypes = () => {
  return dispatch => {
    dispatch(fetchingData());
    SERVICES.getProviderTransactTypes()
    .then(data => dispatch(setProviderTransactTypes(data)) )
    .catch(error => dispatch(fetchDataFailure(error)) );
  }
}

export const setProviderTransactTypes = (data) => {
  return {
    type: CONSTANTS.SET_PROVIDER_TRANSACT_TYPES,
    payload: data
  }
}

export const fetchingAfip = () => {
  return {
    type: CONSTANTS.GET_STATUS_API_REQUEST
  }
}

export const fetchingAfipSuccess = (data) => {
  return {
    type: CONSTANTS.GET_STATUS_API_SUCCESS,
    payload: data
  }
}

export const fetchingAfipFrailure = (data) => {
  return {
    type: CONSTANTS.GET_STATUS_API_FRAILURE,
    payload: data
  }
}

export const getStatusAfip =  () => {
   return dispatch => {
     dispatch(fetchingAfip());
     SERVICES.getAfipStatus()
     .then(response => {
       if(response?.DummyResponse?.appserver && response?.DummyResponse?.dbserver && response?.DummyResponse?.authserver){
         dispatch(fetchingAfipSuccess(response))
       } else {     
         dispatch(fetchingAfipFrailure(response))
       }
     })
     .catch(error => console.log(error))
   }
}