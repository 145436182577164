import React from 'react';
import { connect } from 'react-redux';

import { Container } from 'react-bootstrap'

export default (TransactPage) => {

  const transactLayoutPage = props => {
    return (
      <Container  {...props} className="py-5 mx-auto no-sides-padding" >
        <TransactPage {...props} /> 
      </Container>
    )
  }

  const mapStateToProps = (state) => ({
  });

return connect(mapStateToProps, null)(transactLayoutPage)

}

