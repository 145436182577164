export const faqData = [
  {
    pregunta: `¿QUIÉNES DEBEN INSCRIBIRSE EN EL REGISTRO OFICIAL DE PROVEEDORES DEL ESTADO?`,
    titulo: `Las personas humanas o jurídicas que desean participar de un acto licitatorio.`,
    respuesta: `...“Solo podrán concurrir a las licitaciones públicas y/o privadas las firmas inscriptas o aquellas que se encuentren tramitando su inscripción, siempre que acrediten su aceptación definitiva en el momento de la apertura de las propuestas. La reglamentación precisará los casos excepcionales donde este requisito pueda ser omitido sin alterar el principio general”...
    <footer class="blockquote-footer text-right"><cite title="Fuente">Ley VII – N° 11 (ARTÍCULO 94°)</cite> <br />- Antes Ley N° 2303 - Ley de Contabilidad -</footer>`,
  },
  {
    pregunta: `¿QUIÉNES NO PODRÁN INSCRIBIRSE EN REGISTRO OFICIAL DE PROVEEDORES DEL ESTADO?`,
    titulo: `No podrán inscribirse en el Registro Oficial de Proveedores del Estado:`,
    respuesta: `1.  Los Funcionarios y Empleados a Sueldo del Estado no podrán intervenir como oferentes, apoderados o intermediarios en Licitaciones Públicas, bajo pena de nulidad y cesantías.

    <footer class="blockquote-footer text-right"><cite title="Fuente">Fuente: Constitución Provincial (ARTÍCULO 70°)</cite></footer>

2.  Las empresas en estado de quiebra o en liquidación.

3.  Los inhibidos y los deudores morosos del Estado.

4.  LEY N° 1556 - ARTÍCULO 41°: El personal – sin perjuicio de lo que al Respecto establezcan otras normas – queda sujeto a las siguientes prohibiciones:

5.  b) – Dirigir, administrar, asesorar, patrocinar, representar o prestar servicios remunerados o no, a personas de existencia visible o jurídica, que gestionen o exploten concesiones o privilegios de la Administración en el orden , Nacional, Provincial o Municipal o que fueren Proveedores o Contratista de las mismas.

6.  Recibir directa o indirectamente beneficios originados con Contratos, Concesiones o Franquicias que celebre u otorgue la Administración en el orden Nacional, Provincial o Municipal.

    <footer class="blockquote-footer text-right"><cite title="Fuente">Fuente: Decreto N° 3421/86 ARTÍCULO 10°.</cite> - (Reglamenta Art.94° -Ley de Contabilidad)</footer>`,
  },
  {
    pregunta: `¿LA CERTIFICACIÓN DE FIRMAS, SE PUEDE REALIZAR ANTE UNA ENTIDAD BANCARIA O AUTORIDAD POLICIAL?`,
    respuesta: `Se deberá enviar la documentación autenticada por escribano público y legalizada si correspondiere para finalizar el trámite.
    <footer class="blockquote-footer text-right"><cite title="Fuente">Ley VII – N° 11 (ARTÍCULO 94°)</cite> <br/>-Antes Ley N° 2303 - Ley de Contabilidad-</footer>`,
  },
  {
    pregunta: `¿QUÉ OCURRE SI LA DOCUMENTACIÓN PRESENTADA ESTÁ INCOMPLETA?`,
    respuesta: `Para su inscripción en el Registro Oficial de Proveedores del Estado los interesados deberán presentar
    una solicitud a la Contaduría General de la Provincia, la que otorgará una constancia del inicio del
    trámite. Si en el término de 30 días corridos de efectuada la presentación no se completa la
    documentación exigida, la misma será dada de baja.
    <footer class="blockquote-footer text-right"><cite title="Fuente">Fuente: DECRETO 566/97 A PARTIR DEL 11-05-98 (BOL.OF.N° 9531)</cite></footer>`,
  },
  {
    pregunta: `¿TIENE ALGÚN COSTO EL TRÁMITE DE INSCRIPCIÓN Y RENOVACIÓN DE INSCRIPCIÓN?`,
    respuesta: `Se debe abonar la Tasa de Organismos Externos C.G.P.M – En la Agencia tributaria Misiones (ATM) por un valor de $100.
    <footer class="blockquote-footer text-right"><cite title="Fuente">Fuente: Resolución C.G. N° 445/20.</cite></footer>
    `,
  },
  {
    pregunta: `¿CÓMO OBTENER LA TASA DE ATM C.G.P.M 920?`,
    respuesta: `
1.  Ingresar a: [www.dgr.misiones.gov.ar](https://www.dgr.misiones.gov.ar)
2.  Hacer clic en **TASAS Y SELLOS**
3.  Clic en Emisión de tasas a organismos externos
4.  Hacer clic en la lupa y seleccionar **CGPM 920**
5.  Para obtener la boleta seleccionar el concepto: tasa de actuación
6.  Ingresar el **C.U.I.T.** dar **ENTER** y se completarán automáticamente los datos
7.  Ingresar el monto **$100**
8.  Y generar boleta o pago Web`,
  },
  {
    pregunta: `¿QUÉ OCURRE SI NO SE RENUEVA LA INSCRIPCIÓN EN EL REGISTRO OFICIAL
    DE PROVEEDORES DEL ESTADO?`,
    respuesta: `El sistema le otorga la baja y el trámite que debe realizar posteriormente es de Reinscripción.`,
  },
  {
    pregunta: `¿DONDE SE GESTIONA EL CERTIFICADO DE LIBRE DEUDA ALIMENTARIA NEGATIVO EMITIDO POR EL REGISTRO DE DEUDORES ALIMENTARIOS MOROSOS?`,
    respuesta: `El certificado adjunto debe corresponder a la provincia donde posea su domicilio real  
    Para obtener el certificado de libre deuda alimentaria en Misiones debe seguir los siguientes pasos:

1.  Para solicitar el “Certificado de Libre Deuda Alimentaria” deberá enviar un E-mail a  
    [rpam@jusmisiones.gov.ar](mailto:rpam@jusmisiones.gov.ar) .
2.  A tener en cuenta :
    *   Los datos solicitados son, Nombre, Apellido y DNI (Fotocopia).
    *   El trámite es gratuito.
    *   Solo se pueden expedir certificados a personas físicas, no a empresas.
    *   Se retira al otro día de su tramitación.
    *   Tiene una vigencia de 30 días desde su solicitud.
    *   La atención es de Lunes a Viernes de 7:00 hs. a 12:00 hs.
    *   Rivadavia N° 2035 esq. Rioja de la Ciudad de Posadas
<footer class="blockquote-footer text-right"><cite title="Fuente">Fuente: LEY IV-N° 31 </cite>(ANTES LEY 3615) ART N° 9 Y ART N° 11</footer>`,
  },
  {
    pregunta: `¿CÓMO RECUPERAR EL USUARIO Y CONTRASEÑA?`,
    respuesta: `1.  Ingresar a [https://mistramites.misiones.gob.ar/](https://mistramites.misiones.gob.ar/). y hacer click en recuperar contraseña.
2.  Para ello debe contar con el número de cuit y correo electrónico declarado al momento de crear la cuenta.`,
  },
  {
    pregunta: `¿DONDE SE DEBE PRESENTAR LA SOLICITUD DE INSCRIPCIÓN,
    REINSCRIPCIÓN, ACTUALIZACIÓN O MODIFICACIÓN DE DATOS DE
    PROVEEDORES Y LA DOCUMENTACIÓN COMPLEMENTARIA?`,
    respuesta: `Una vez completado el trámite de manera digital y de haberse emitido el certificado de inicio
    pertinente, deben remitir la carpeta completa con las certificaciones correspondientes a Av. Polonia
    1223 Posadas, Misiones. CP:3300`,
  },
  {
    pregunta: `¿EN QUÉ CONSISTE EL TRÁMITE DE ACTUALIZACIÓN?`,
    respuesta: `El trámite de actualización es aquel que realiza una persona - humana o jurídica - dentro de los 30
    días previos al vencimiento de su inscripción.`,
  },
  {
    pregunta: `¿EN QUÉ CONSISTE EL TRÁMITE DE REINSCRIPCIÓN?`,
    respuesta: `El trámite de reinscripción es aquel que realiza una persona - humana o jurídica - que tiene una inscripción que se encuentra vencida y para reactivar su registro, debe realizar éste.`,
  },
  {
    pregunta: `¿QUÉ VIGENCIA POSEE LA INSCRIPCIÓN, ACTUALIZACIÓN Y REINSCRIPCIÓN EN EL REGISTRO OFICIAL DE PROVEEDORES DEL ESTADO?`,
    respuesta: `La inscripción en el Registro Oficial de Proveedores del Estado a partir de la fecha de su otorgamiento, deberá ser renovada cada dos años, mediante solicitud a ser presentada ante la Contaduría General con una antelación mínima de 30 días previos al cumplimiento de dicho plazo.

Sin embargo, y dado que la fecha de vigencia de la inscripción/actualización/reinscripcion se encuentra ligada a la fecha de vencimiento de la habilitación municipal - si tuviera -, si ésta venciera antes de los dos años, la inscripción/actualización/reinscripción se otorgará por hasta esa fecha.
    
En el caso de Modificación de datos, Usted no podrá modificar la vigencia del mismo, la cual estará sujeta a la vigencia otorgada en el trámite de Inscripción, Actualización o Reinscripción.
<footer class="blockquote-footer text-right"><cite title="Fuente">Fuente: Decreto 3421/86 – Artículo 10</cite></footer>`,
  },
  {
    pregunta: `¿ME PUEDO PRESENTAR A UN ACTO LICITATORIO SIN ESTAR INSCRIPTO EN EL REGISTRO OFICIAL DE PROVEEDORES DEL ESTADO?`,
    respuesta: `Si. Siempre y cuando al momento de presentación de los sobres adjunte el certificado de inicio de  
trámite y luego al momento de la apertura debe presentar el certificado definitivo con su número de  
proveedor correspondiente.

A efectos de lo dispuesto en la última parte del Art.94 de la Ley se establece que serán admitidas  
ofertas de proponentes no inscriptos, en los siguientes casos:

1.  Cuando se trate de artistas, técnicos o profesionales.
2.  Si las ofertas provienen de firmas extranjeras sin agente o representante en el país, cuando no  
    hubiere ofertas convenientes de proveedores inscriptos en el ramo.
3.  Cuando se trate de locar, arrendar o comprar inmuebles.
4.  En las ventas de bienes, arrendamientos, locación de inmuebles del Estado y concesiones en  
    general, que efectúe la Administración Provincial.
5.  Los organismos nacionales, provinciales o municipales, incluso las Empresas del Estado y las  
    Sociedades de Economía Mixta.
6.  Empresas acreditadas en el ramo, cuando no exista el número mínimo de inscriptos, o por las  
    características de la compra, hayan sido invitados por expresa autorización de la Repartición  
    solicitante.
    <footer class="blockquote-footer text-right"><cite title="Fuente">Fuente: Ley VII – N° 11 (ARTÍCULO 94°)
<br/>-Antes Ley N° 2303 - Ley de Contabilidad</cite></footer>`,
  },
  {
    pregunta: `¿CÓMO SE REALIZA EL TRÁMITE DE ACTUALIZACIÓN?`,
    respuesta: `Debe ingresar a Sistema de Trámites https://sistema.proveedores.misionescontaduria.com/ y seguir los pasos hasta cumplimentarlo, en ese momento el sistema le envía a su e-mail una constancia de inicio del trámite.`,
  },
  {
    pregunta: `¿CÓMO SE REALIZA EL TRÁMITE DE INSCRIPCIÓN?`,
    respuesta: `Debe ingresar a Sistema de Trámites [https://sistema.proveedores.misionescontaduria.com/](https://sistema.proveedores.misionescontaduria.com/) y seguir los pasos hasta
    cumplimentarlo, en ese momento el sistema le envía a su e-mail un inicio de trámite.`,
  },
  {
    pregunta: `¿QUÉ DEBO HACER SI TENGO ALGÚN INCONVENIENTE EN EL MOMENTO DE CREACIÓN DE LA CUENTA O EN ALGUNO DE LOS PASOS HASTA FINALIZAR EL TRÁMITE ONLINE?`,
    respuesta: `Se Debe enviar un correo electrónico a cgproveedores@misiones.gov.ar con los siguientes datos:

- Nombre.
- CUIT que usó para la creación de la cuenta.
- Teléfono de contacto.
- Correo que usó para la creación de la cuenta.
- Foto del error que ocurre.
- Descripción del error que está experimentando.`,
  },
];
