import React, { useRef, useEffect } from 'react';

import { Form, FormControl, Card, Accordion, Button } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import ReactGA from "react-ga";

const HashVerificationComponent = (props) => {

  const { hashValue } = props;

  const { inputValueHandler, searchHashHandler, cleanHashValue } = props.handlers;

  const inputHasValue = useRef();

  let auxHashValue = hashValue === null ? "" : hashValue;


  const validateEnterKey = (e, val) => e.target.value.length > 5 && e.key === "Enter" ? searchHashHandler(val) : null;

  const getInputValues = inputHasValue.current && inputHasValue.current.value ? false : true;

  const verifyClick = () => {
      ReactGA.event({
          category: 'Constancia de inscripción',
          action: 'Verificar',
      });
      searchHashHandler(hashValue);
  }

  return (
      <Card className="border-0 box-shadow">
        <Card.Header className="bg-white">
          <h4 className="mb-1 px-3">Verificación de constancia de inscripción</h4>
              <small className="text-muted px-3">Aquí usted podrá consultar el listado de los Proveedores habilitados por código o escaneando el QR.</small>
        </Card.Header>

          <Card.Body className="px-3 px-md-6">
            <Card>
              <Card.Header as="h5">Código de Verificación</Card.Header>
                <Card.Body>
                  <Form.Group className="col-lg-5">
                    <Form.Label>Código verificador</Form.Label>

                      <FormControl name="codigo_verificador" aria-describedby="Código verificador"
                        onChange={ (e) => inputValueHandler(e)}
                        onKeyPress={ (e) => validateEnterKey(e, hashValue)}
                        value={auxHashValue}
                        ref={inputHasValue}
                      />

                    </Form.Group>
                </Card.Body>
            </Card>
            <Card.Footer className="text-muted d-flex bg-white justify-content-between">
              <Button className="btn btn-danger general-hover-animation"
                onClick={verifyClick} disabled={getInputValues}>
                  Consultar{<span> ></span>}
              </Button>

              <button class="btn btn-secondary px-4 general-hover-animation" onClick={ (e) => cleanHashValue(e)} >Limpiar
              <FontAwesomeIcon className="mx-1" icon={faTrashAlt} size="sm"></FontAwesomeIcon>
              </button>
            </Card.Footer>
          </Card.Body>

      </Card>
  )
}

export default HashVerificationComponent;
