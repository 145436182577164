import React from 'react';
import { connect } from 'react-redux';
import { Navbar } from 'react-bootstrap';
import { push } from 'connected-react-router';

import logo from '../../../public/logo-gobierno-misiones-contaduria-general.png';

import Menus from './menus'

const Navigation = (props) => {

  const isMobile = window.screen.width <= 800 && window.screen.width <= 600;

  return (
    <Navbar expand="lg" className="px-0 headerNav">
        <Navbar.Brand className="pl-2 mr-5">
            {/* <div className="logo"></div> */}
            <a href="/">
              <img alt="Contaduría General de la Provincia de Misiones" class="logo" src={logo} width={isMobile ? "285" : "300"} />
            </a>
        </Navbar.Brand>

        <Navbar.Toggle ariaControls="basic-navbar-nav" />

        <Navbar.Collapse id="basic-navbar-nav">

          <Menus />
            
        </Navbar.Collapse>
    </Navbar>
  );
}

const mapStateToProps = state => {
    return {
        pathname: state.router.location.pathname,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        goTo: (URL) => dispatch(push(URL)),
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Navigation);